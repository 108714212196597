<template>
  <div>
    <loader v-if="isLoading"></loader>
    <div v-else class="wrapper">
      <div class="header">
        <div class="header__about-company">
          <img class="logo" src="@/assets/logo.png">
          <div class="header__company-info">
            <p><strong>ООО МКК «ФИНМОЛЛ»</strong></p>
            <p>630112, г. Новосибирск, <br> ул. Фрунзе, д. 242 пом. 406</p>
            <p>тел: 8 800-600-99-90</p>
            <p><b>Свидетельство МФО:</b></p>
            <p>65140355005541</p>
            <p><a href="http://www.finmoll.ru">http://www.finmoll.ru/</a></p>
            <p><b>Служба контроля качества:</b></p>
            <p>8 800-600-19-94</p>
            <p>help@msk.finmoll.com</p>
            <div class="header__inputs">
              <div>
                <input type="checkbox" v-if="dg.DealProductType_Id == 2" checked disabled>
                <input type="checkbox" v-else disabled>
                <label>Базовый</label>
              </div>
              <input type="checkbox" v-if="dg.DealProductType_Id == 4" checked disabled>
              <input type="checkbox" v-else disabled>
              <label>Индивидуальный</label>
            </div>
          </div>
        </div>
        <div class="header__right">
          <div class="credit-costs">
            <div class="credit-cost">
              <div>
                <p class="credit-cost__title"><b>Полная стоимость займа: {{dg.PercentageRate}}%</b></p>
                <p>{{PercentageRateLettr}} целых ноль тысячных процентов годовых</p>
              </div>
            </div>
            <div class="credit-cost">
              <div>
                <p class="credit-cost__title"><b>Полная стоимость займа:{{dg.OutPayments}} рублей</b></p>
                <p>{{OutPaymentsLettr}}</p>
              </div>
            </div>
          </div>
          <div class="agent">
            <div class="agent__top">
              <p>Код Агента: {{dg._moss_sotrudniki_manager_Code}}</p>

            </div>
            <div class="agent__bottom">
              <p>ФИО Агента: {{dg.AgentFio}}</p>
            </div>
          </div>
        </div>

      </div>


      <section class="section">
        <p><strong>Заявление на несовершение ЧДП</strong></p>
        <p>Я, </p>
        <table id="t4">
          <tr>
            <td>Фамилия</td>
            <td>{{dg.ClLastName}}</td>
          </tr>
          <tr>
            <td>Имя</td>
            <td>{{dg.ClFirstName}}</td>
          </tr>
          <tr>
            <td>Отчество</td>
            <td>{{dg.ClMiddleName}}</td>
          </tr>
          <tr>
            <td>Паспорт</td>
            <td>
              <table>
                <tr>
                  <td>
                    Серия
                  </td>
                  <td>
                    {{dg.PassportSeria}}
                  </td>
                  <td>
                    Номер
                  </td>
                  <td>
                    {{dg.PassportNumber}}
                  </td>
                  <td>
                    Дата выдачи
                  </td>
                  <td>
                    {{dg.PassportDate}}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    Кем выдан
                  </td>
                  <td colspan="4">
                    {{dg.PassportDepIssue}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <p>Прошу Общество с ограниченной ответственностью Микрокредитная компания «ФИНМОЛЛ» (ОГРН 1117746509638),
          расположенному по адресу: 630112, Новосибирская область, город Новосибирск, улица Фрунзе, дом 242, помещение 406 (далее – Общество)</p>
        <p>В случае, если в течение четырнадцати календарных дней с даты выдачи мне Обществом займа № 00{{dg.DemandID}}
          мною будут внесены денежные средства в погашение по данному договору в сумме большей, чем это необходимо для погашения моих обязательств, дата которых в соответствии с Графиком платежей на момент внесения платежа наступила, но недостаточной для полного погашения моих обязательств по договору займа, учитывать данные суммы в исполнение моих обязательств в соответствии с Графиком платежей и не производить частичное досрочное погашение моей задолженности без отдельного уведомления о намерении вернуть досрочно часть займа с моей стороны.</p>
        <signature
          :LastName="dg.ClLastName"
          :FirstName="dg.ClFirstName"
          :MiddleName="dg.ClMiddleName"
          :DemandDate="dg.DemandDate"
          :DataConfirm="dg.DataConfirm"
        ></signature>

      </section>

      <pdnIl v-if="dg.pdnShow" class="pagebreak"></pdnIl>
    </div>
  </div>



</template>
<script>
  export default {
    name: "pdl_IU",
    data() {
      return {
        isLoading: true,
        dg: {
          ClLastName: '',
          ClFirstName: '',
          ClMiddleName: '',
          PassportSeria: '',
          PassportNumber: '',
          PassportDepIssueCode: '',
          BIK: '',
          AccountNumber: '',
          PercentageRate: '',
          PercentageRateArr: [],
          Amount: '',
          OutPayments: '',
          DemandID: '',
          DemandDate: '',
          BankName: '',
          PassportDepIssue: '',
          Phone: '',
          SNILS: '',
          EMail: '',
          FactAddress: '',
          RegAddress: '',
          PassportDate: '',
          DataConfirm: '',
        },

      }
    },
    components: {
      loader: () => import('@/components/other/loader'),
      signature: () => import('@/components/other/signature'),
      pdnIl:()=>import('@/views/agreement/confirmPdnIl')
    },
    mounted() {
        this.$external.universalRequest({
          externalType: 'zita',
          action: 'getDealIU',
          DemandGuid: this.$route.params.uid
        }).then((res) => {
          console.log(res);
          this.dg = res;
          this.isLoading = false;
        }).catch((res) => {
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        })
    },
    computed: {
    }
  }
</script>


<style scoped>

  table{width: 100%;border-collapse: collapse}

  td{padding: 2px;}

  .flex {
    display: flex;
  }

  .ft{justify-content: space-between;align-items: flex-start}


  .wrapper {
    padding: 10px;
    font-size: 10px;
    line-height: 1.5;
  }

  .section {
    margin-bottom: 30px;
  }

  .section p {
    margin-bottom: 5px;
  }

  .logo {
    width: 200px;
    margin-bottom: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .header__company-info {
    min-width: 180px;
    font-size: 10px;
    padding-left: 15px;
  }

  .header__right {
    padding-left: 30px;
  }

  .header__company-info p {
    margin-bottom: 5px;
  }

  .header__company-info p {
    margin-bottom: 5px;
  }

  .header__inputs {
    margin-top: 10px;
  }

  .header__inputs label {
    margin-left: 5px;
  }

  .credit-costs {
    display: flex;
    justify-content: space-between;
  }

  .credit-cost {
    width: 200px;
    height: 200px;
    padding: 10px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
  }

  .credit-cost__title {
    margin-bottom: 10px;
  }

  .agent {
    width: 100%;
    border: 1px solid black;

  }

  .agent__top {
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 10px 5px;
    margin-bottom: 10px;
  }

  .agent__bottom {
    align-items: center;
    padding: 10px 5px;
  }

  .agent__top p:first-child {
    margin-right: 100px;
  }




  .main p {
    margin-bottom: 5px;
  }

  .conditions {
    margin-bottom: 20px;
  }

  table,
  tr,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid black;
    vertical-align: top;
  }

  td:nth-child(3) {
    vertical-align: middle;
  }

  .conditions td:first-child {
    text-align: center;
  }

  .conditions table td:first-child {
    min-width: 100px;
  }

  .conditions td:nth-child(2) {
    min-width: 300px;
  }

  .conditions td:nth-child(3) {
    min-width: 300px;
  }

  .conditions th {
    background-color: rgb(216, 212, 212);
  }

  .signature {
    height: 40px;
    margin-top: 10px;
  }



  p {
    margin-bottom: 0;
  }


  .user-info-table {
    width: 100%;
  }

  .qr {
    text-align: end;
  }

  .qr img {
    width: 130px;
  }



  @media (min-width: 1200px) {
    .wrapper {
      font-size: 16px;
    }

    .header__company-info {
      font-size: 16px;
    }
  }

  @media only print {
    .pagebreak { page-break-before: always; }
    .section {
      margin-bottom: 5px;
    }

    .section p {
      margin-bottom: 0px;
    }

    .header__right {
      width: 500px
    }

    .credit-cost {
      height: 150px;
      width: 200px;
    }

    .agent__top {
      padding: 2px;
      margin-bottom: 0px;
    }

    .agent__bottom {
      padding: 2px
    }


    .signature {
      margin-top: 10px;
    }

    table {
      margin: 0
    }

    .header {
      margin-bottom: 10px;
    }

    .wrapper {
      font-size: 9px;
      line-height: 1.1;
    }

    .logo {
      width: 150px
    }
    .qr img {
      width: 100px;
    }

  }


  @media only screen and (min-width: 320px) and (max-width: 780px) {
    .wrapper {
      font-size: 16px;
    }

    .credit-cost {
      width: 50%;
      padding: 5px;
      min-height:300px;
    }

    .header__company-info {
      font-size: 16px;
    }
  }
</style>
